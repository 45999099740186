
.logo{
    height: 40px;
    width: 40px;
}

.nav-cont{

}

.nav-cont ul{
    color: #fff;
    width: 100%;
    transition: all 0.5s ease;
    list-style: none;
    padding-top: 10px;
    margin-left: -21px;
  }

.nav-cont a{
    color: #fff;
    text-decoration: none;
  }

  
  
  
@media only screen and (device-width: 768px) {
    /* For general iPad layouts */
    
   
  }
  
  @media only screen and (min-device-width: 350px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    .nav-cont ul{
        color: #fff;
        width: 100%;
        transition: all 0.5s ease;
        list-style: none;
        text-align: center;

      }
  }
  
  @media only screen and (min-device-width: 350px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
    
  }